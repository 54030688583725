@import url('https://fonts.cdnfonts.com/css/helvetica-neue-9');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "HelveticaNeue";
  src: url("./fonts/Helvetica\ Neue\ UltraLight.ttf") format("truetype");
  src: url("./fonts/Helvetica\ Neue\ UltraLight.otf") format("opentype");
  font-weight: normal;
}


body {
    font-family: "HelveticaNeue", sans-serif;
}

